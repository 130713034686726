
import App from '../../molecules/App'
import MyDashboard from '../../pages/MyDashboard'
import ManagePhotos from '../ManagePhotos'
import Dashboard from './Dashboard'
import ManagePhotographer from './Managephotographer'


const Pages={
    Dashboard,
   ManagePhotographer,
   App,
   MyDashboard,
   ManagePhotos
}
export default Pages
