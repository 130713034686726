import React, { useState } from "react";
// import { FiEye } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import constant from '../../constants/constant'
import atoms from '../../components/atoms'
import * as Yup from "yup";
import { useFormik } from "formik";

import {
    Label, Input, Modal, Form, FormFeedback,
    ModalHeader,
} from "reactstrap"
import './manage.scss'
const { Table, } = atoms

const Index = ({ setLimitPerPage, setCurrentPage,currentPage,limit, photosList, }: any) => {
    const navigate = useNavigate()
    console.log("photo++++", photosList)

    const [showModal, setShowModal] = useState(false);

    const validation = useFormik<any>({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            oldPassword: '',
            newPassword: '',
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string().required("Please Enter Your oldPassword"),
            newPassword: Yup.string().required("Please Enter Your newPassword"),
        }),
        onSubmit: (values) => {
            setShowModal(false);

            // dispatch(loginUser(values, props.router.navigate));
        }
    });
    let startIndex = (photosList?.currentPage - 1)*photosList?.limit
    // console.log("startIndex",startIndex+1)
    const columns = [
        {
            name: 'S.No',
            selector: (row: any, index: number) => (startIndex + index + 1).toString().padStart(2, '0'), width: "50px",
             },
        {
            name: 'Firm Name',
            selector: (row: any) => row?.firm_name, minWidth: "10px"
        },
        {
            name: 'Owner Name',
            selector: (row: any) => row?.user_id[0]?.name,
        },
        {
            name: 'Phone Number',
            selector: (row: any) => row?.user_id[0]?.mobile,
        },
        {
            name: 'Address',
            selector: (row: any) => row?.firm_address,
            cells: (row: any) => <div title={row.firm_address}> {row.firm_address}</div>,
        },

        {
            name: 'Email',
            selector: (row: any) => row?.user_id[0]?.email,
        },
        {
            name: 'Action',
            selector: (row: any) => row.action,
        },
    ];
    return (
        <div>
            <div>
                <>{console.log("photosList?.limitphotosList?.limit", photosList?.limit)}</>
                <Table
                    pagination={true}
                    paginationTotalRows={photosList?.count}
                    setLimitPerPage={(e: number) => setLimitPerPage(e)}
                    setCurrentPage={(e: number) => setCurrentPage(e)}
                    columns={columns}
                    currentPage={currentPage}
                    data={photosList?.data && photosList?.data
                        ?.map((item: any, index: number) => {
                            return ({
                                ...item,
                                status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                isActive: item.isActive ? (
                                    <div className="background-light-green border-product-radius">
                                        <p className="color-green m-1 p-1">{constant.STATUS.ACTIVE}</p>
                                    </div>) : <div className="background-light-red border-product-radius">
                                    <p className="color-red m-1 p-1">{constant.STATUS.INACTIVE}</p>
                                </div>,
                                action:

                                    <> <div className='table-data-width'>
                                        <button
                                            className="edit-btn pe-4 px-3 "
                                            onClick={() => navigate(`/photographer/edit/${item._id}`)}
                                        >
                                            <span className='ms-2 mb-0'>Edit</span>
                                        </button>
                                        <>
                                            <div>
                                                <Modal
                                                    isOpen={showModal}
                                                    role="dialog"
                                                    autoFocus={true}
                                                    centered
                                                    data-toggle="modal"
                                                    toggle={() => {
                                                        setShowModal(!showModal);
                                                    }}
                                                    style={{ width: "350px" }}
                                                >
                                                    <ModalHeader className="justify-content-center">
                                                        <div>
                                                            Change Password
                                                        </div>
                                                    </ModalHeader>
                                                    <div className="modal-body mx-3">
                                                        <Form
                                                            className="form-horizontal"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                validation.handleSubmit();
                                                                return false;
                                                            }}
                                                        >
                                                            <div className="mb-3">
                                                                <Label className="form-label">Old Password</Label>
                                                                <Input
                                                                    name="oldPassword"
                                                                    className="form-control"
                                                                    placeholder="Enter Old Password"
                                                                    type="password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.oldPassword || ""}
                                                                    invalid={
                                                                        validation.touched.oldPassword && validation.errors.oldPassword ? true : false
                                                                    }
                                                                />
                                                                {validation.touched.oldPassword && validation.errors.oldPassword ? (
                                                                    <FormFeedback type="invalid"></FormFeedback>
                                                                ) : null}
                                                            </div>

                                                            <div className="mb-3">
                                                                <label className="form-label">New Password</label>
                                                                <input
                                                                    name="newPassword"
                                                                    value={validation.values.newPassword || ""}
                                                                    type="password"
                                                                    placeholder="Enter New Password"
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}

                                                                />
                                                                {validation.touched.newPassword && validation.errors.newPassword ? (
                                                                    <form >b</form>
                                                                ) : null}
                                                            </div>
                                                            <div className="mt-3 text-center  ">
                                                                <button
                                                                    className="btn btn-primary btn-block"
                                                                    type="submit"
                                                                // onClick={()=>handleClick(validation.values)}
                                                                >
                                                                    Confirm
                                                                </button>
                                                            </div>

                                                        </Form>
                                                    </div>
                                                </Modal >
                                            </div>
                                        </>
                                    </div></>




                                // ========================================================
                                // ...item,
                                // status: <div className={`${item.status === "active" ? "green-status" : "red-status"}`}>{item.status}</div>,
                                // action:
                                //     <Link
                                //         to={`/contractors/edit/${item.id}`}
                                //     >
                                //         <Button className=" btnEdit table-edit-button fs-12">
                                //             <div className='d-flex align-items-center'>
                                //                 <FiEye />
                                //                 <span className='ms-2 mb-0'>View</span>
                                //             </div>
                                //         </Button>
                                //     </Link>





                            })
                        })
                    }
                />
            </div>
        </div>
    )
}

export default Index