import React from 'react'
import { InputInterface } from './interface'
import './input.scss'
const Input = ({ id, type, name, disabled, defaultValue,
    onChange, errors, className, placeholder, label,
    htmlFor, accept, multiple, value, checked, required,
    ref, maxLength,multiLable,getPassword }: InputInterface) => {
    const handleKeyDown = (event: any) => {
        if (type === 'number' && (event.key === 'ArrowUp' ||
            event.key === 'ArrowDown' || event.key === 'e' ||
            event.key === '+' || event.key === '-')) {
            event.preventDefault();
        }
    }

    const getRandomPassword =()=>{
        let chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
        let passwordLength = 12;
        let password = "";

        for (let i = 0; i <= passwordLength; i++) {
            let  randomNumber = Math.floor(Math.random() * chars.length);
            password += chars.substring(randomNumber, randomNumber +1);
           }
           getPassword(password)
    }
    return (
        <div className="form-group">
      {label && (
        <div className='d-flex justify-content-between'>
          <label className='inputs-label mb-2 fs-16 fw-600 secondary-color' htmlFor={htmlFor}>
            {label}
          </label>
          {multiLable && (
            <label className='inputs-label mb-2 fs-16 fw-600 secondary-color text-end' onClick={() => getRandomPassword()}>
              {multiLable}
            </label>
          )}
        </div>
      )}

      {type === 'textarea' ? (
        <textarea
          id={id}
          className={`inputs form-control shadow-none ${className}`}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange as React.ChangeEventHandler<HTMLTextAreaElement>}
          placeholder={placeholder}
          value={value}
          required={required}
          ref={ref}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        />
      ) : (
        <input
          id={id}
          className={`inputs form-control shadow-none ${type === 'radio' ? '' : 'inputs'} ${className}`}
          type={type}
          name={name}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          accept={accept}
          multiple={multiple}
          value={value}
          checked={checked}
          required={required}
          ref={ref}
          onKeyDown={handleKeyDown}
          disabled={disabled}
          maxLength={maxLength}
        />
      )}
      {errors && <span className="error-text">{errors}</span>}
    </div>
  );
};
export default Input;