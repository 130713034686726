import React from 'react'
import Header from '../common/Header/header'
import SideBar from '../common/Sidebar/sidebar';
import { Navigate } from 'react-router';

const AuthLayout = (props: any) => {
    if (!localStorage.getItem("access_token")) {
        return (
            <Navigate to={{ pathname: "/login", }} />
        );
    }
    return (
        <React.Fragment>
            <div id="layout-wrapper">
                <Header />
                <SideBar />
                <div className="main-content">{props.children}</div>
            </div>
        </React.Fragment>
    )
}

export default AuthLayout