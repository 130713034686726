import { BASE_URL } from '../../constants/defaultValues'
import Dictionary from '../dictionary'
import axios from 'axios'

const { managephotos } = Dictionary

const ManagePhotos = {
  view: async (paramsObj:any) => {
    return axios.get(BASE_URL + managephotos.photos(),{ params: paramsObj })
  },
  add: async (data: {}) => {
    return axios.post(BASE_URL + managephotos.addPhotog(), data)
  },
  status: async (id: string) => {
    return axios.put(BASE_URL + managephotos.photosById(id))
  },
  edit: async (id: string, data: {}) => {
    return axios.put(BASE_URL + managephotos.photosById(id), data)
  },
  getDetailById: async (id: string) => {
    return axios.get(BASE_URL + managephotos.photosById(id))
  },
  changePassword: async (id:string,data: {})=>{
    return axios.put(BASE_URL + managephotos.changePassword(id),data);
},
}
export default ManagePhotos
