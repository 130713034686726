

const Index = () => {

    return(
        <div>App
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima illum, placeat dolor totam cupiditate atque fugit iste accusantium ducimus omnis ipsa nam quae vel natus nobis aliquam repudiandae! Explicabo.
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum aspernatur vel quisquam. Quasi ea itaque error ab ut cum perspiciatis dolorem! Exercitationem, facilis aperiam non soluta hic perferendis modi?
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet sed dolor, voluptate accusantium illum earum necessitatibus iusto porro? Asperiores adipisci repellendus ut saepe mollitia molestias itaque enim necessitatibus alias similique.
        </div>
    )
}
export default Index