import { Console } from 'console';
import React from 'react'
import DataTable from 'react-data-table-component';
import './table.scss'
const Index = ({
  columns, data,
  selectableRows,
  handleSelectedRow,
  paginationTotalRows,
  setCurrentPage,
  setLimitPerPage,
  // pageCount,
  pagination,className }: any) => {

    const customStyles = {
      headCells: {
          style: {
            fontWeight: 'bold',
              paddingLeft: '8px', // override the cell padding for head cells
              paddingRight: '8px',
              paddingTop:'15px',
              paddingBottom:'15px',
              // background:'#515862',
              borderBottom:'1px solid  background-color: rgba(81, 88, 98, 0.5); /* #515862 with 50% opacity */',
              fontSize:'14px',
              color:'#515862',
          
          },
      },
      rows: {
          style: {
            
              minHeight: '60px', // override the row height
              border:'1px solid  background-color: rgba(81, 88, 98, 0.5); /* #515862 with 50% opacity */',
          },
      },
      cells: {
          style: {    
            fontSize:'15px',
            borderBottom:'1px solid #D3D3D3', 
              paddingLeft: '8px', // override the cell padding for data cells
              paddingRight: '8px',
          },
          
      },
  };
  return (
    <DataTable
// pageCount={pageCount}
      className={''}
      customStyles={customStyles}
      columns={columns}
      data={data}
      pagination={pagination}
      striped
      selectableRows={selectableRows}
      onSelectedRowsChange={handleSelectedRow}
      selectableRowsHighlight
      paginationServer
      paginationTotalRows={paginationTotalRows}
      onChangeRowsPerPage={(limit) => setLimitPerPage(limit)}
      onChangePage={(page) => setCurrentPage(page)}
    />
  )
}

export default Index