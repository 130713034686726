import React from "react";
import Pages from '../pages/App/index'
import Auth from "../pages/Auth/lindex"
import { Navigate } from "react-router-dom";
import Managephotographer from "../pages/App/Managephotographer";
import AddPhotographer from "../pages/AddPhotographer";

const { MyDashboard ,App,ManagePhotos} = Pages
const { Login } = Auth
const privateRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />, },
    { path: '/mydashboard', component: <MyDashboard /> },
    // { path: '/dashboard', component: <Dashboard /> },
    { path: '/app', component: <App /> },
    { path: '/managephotgrapher', component: <Managephotographer /> },
    { path: '/managephotos', component: <ManagePhotos /> },
    { path: '/photographer/add', component: <AddPhotographer /> },
    { path: '/photographer/edit/:id', component: <AddPhotographer /> },
   
 


]
const publicRoutes = [
    { path: "/", exact: true, component: <Navigate to="/login" />, },
    { path: '/login', component: <Login /> },
    



]

export { privateRoutes, publicRoutes }