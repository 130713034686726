import React, { useState } from 'react'
import Wrapper from '../../layout/Wrapper/wrapper'
import CommonCard from '../../layout/Card/CommonCard/commoncard'
import molecules from '../../molecules'
import atoms from '../../components/atoms'
import { TbCalendarStar } from 'react-icons/tb'
import { RxDashboard } from 'react-icons/rx'
import { MdOutlinePhotoLibrary } from 'react-icons/md'
import './index.scss'
import TopBar from '../../layout/Topbar/topbar'
import { NavLink } from 'reactstrap'
import { useNavigate } from 'react-router-dom';

const { Button } = atoms





const Index = ({ managePhotos, photoList, dashboard, }: any) => {
  // const [dashboard_Data, setDashboard_Data] = useState<any>([])
  const navigate = useNavigate();
  console.log("dashboard", dashboard)
  return (
    <>
      <Wrapper>
        <div className="card d-flex">
          <div className=' col-md-6 d-flex mt-5 mx-5'>
            <div className=" card-body-first pt-3 px-5" onClick={() => navigate('/managephotos')}>
              <MdOutlinePhotoLibrary className='person-l' />
              <p className='h-para'>Total Photographers</p>
              <div className='mb-3 h-num'> <h3> {managePhotos?.total_photographers}</h3> </div>
            </div>
            
              <div className={" card-body-second pt-3 px-5 mx-5"} onClick={() => navigate('/managephotos')}>
                <TbCalendarStar className='person-l' />
                <>
                  {console.log("managePhotos", managePhotos)}
                </>
                <p className='h-para'>Total Events</p>
                <h3 className='mb-3 h-num'> {managePhotos?.total_events}</h3>
              </div>
              
            </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Index