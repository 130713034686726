import ManagePhotos from '../molecules/ManagePhotos'
import App from './App'
import Dashboard from './dashboard'
// import managephotographer from './managephotographer'
import myDashboard from './MyDashboard'
const molecules = {
    Dashboard,
    // managephotographer,
    App,
    myDashboard,
    ManagePhotos

    
}
export default molecules