import React, { useState } from "react";
import "./header.scss";
import { GrNotification} from "react-icons/gr";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { CgProfile } from "react-icons/cg";
import { TbLogout } from "react-icons/tb";
import atoms from '../../components/atoms'
import service from "../../api/services";
import { useNavigate } from "react-router-dom";
import { useToasts } from 'react-toast-notifications';
// const { Image } = atoms;

const Header = ({ loginResponse }: any) => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [toggleDropdown, setToggleDropdown] = useState(false);
  let LoginData: any = localStorage.getItem("loginResponse");
  let userName = JSON.parse(LoginData);
  const logout = async () => {
    try {
      let response = await service.auth.logout();
      console.log("Authent" ,response)
      addToast(response?.data?.message, {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 3000,
      });
      localStorage.setItem("access_token", "");
      navigate("/login");
    } catch (error) {
      console.log(error);
    }
  };
  const handleDropdownClick = () => {
    setToggleDropdown(!toggleDropdown);
  };
  return (
    <div className="">
      <div className="s-layout">
        <main className="s-layout__content header-bg justify-content-between">
          <div>
            <h3 className="mb-0 fs-22 fw-600">Dashboard</h3>
          </div>
          <div>
            <div className="me-4 d-flex gap-4 align-items-center">
              <Dropdown
                group
                isOpen={toggleDropdown}
                size="lg"
                toggle={() => setToggleDropdown(!toggleDropdown)}
              >
                <div className="d-flex align-items-center gap-3 dropdown-button-hide">
                  <div className="cursor" onClick={handleDropdownClick}>
            
                  <div><span><CgProfile className='mb-0 fs-2'/></span></div>
                    
                  </div>
                  
                  {/* <div className="gr-not"><span className="gr-not">< GrNotification className='mb-0 fs-4 '/></span></div> */}
                  {/* <h5 className="mb-0 fs-12 fw-400">profile2</h5> */}
          
                </div>
                <DropdownToggle className="dropdown-button-hide cursor-pointer"></DropdownToggle>
                <DropdownMenu className="mt-3">
                  <div className="p-0 d-flex header-dropdown align-items-center gap-1 ps-3 cursor-pointer logot">
                    <TbLogout />
                    <span
                      className="cursor-pointer p-2"
                      onClick={() => logout()}
                    >
                      Logout
                    </span>
                  </div>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Header;
