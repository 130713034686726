import React, { useEffect, useState } from "react";
import Wrapper from "../../layout/Wrapper/wrapper";
import Card from "../../layout/Card/card";
import atoms from "../../components/atoms";
// import constant from "../../constants/constant";
import { validationRules } from "./validate";
import { useParams } from "react-router-dom";
import "../../molecules/ManagePhotos/manage.scss";
import './addphoto.scss'
import { useToasts } from 'react-toast-notifications'
import { useFormik } from "formik";
import { Label, Modal, Form, FormFeedback, ModalHeader, } from "reactstrap"
import service from "../../api/services";
import ReactTooltip from 'react-tooltip';


const { Input, Button, } = atoms;
const Index = (
  {
    photoData, photographerData,
    setPhotographerData, setButtonClick,user_id

  }: any) => {
  const { addToast } = useToasts();
  const params: any = useParams();
  const editId = params?.id
  const [errors, setErrors] = useState<any>({});

  const handleChange = (name: string, value: any) => {
    console.log("value==>>", name, value);

    setPhotographerData((prevState: any) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors((prevErrors: any) => ({ ...prevErrors, [name]: "" }));
  };

  const [showModal, setShowModal] = useState(false);


  const validationModal = useFormik<any>({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      // password: '',
      // confirm_password: '',
    },

    onSubmit: (values) => {
      setShowModal(false);

      // dispatch(loginUser(values, props.router.navigate));
    }
  });
  const validate = () => {
    let newErrors: any = {};
    for (const field in validationRules) {
      if (validationRules.hasOwnProperty(field)) {
        if (validationRules[field]?.required && !photographerData[field]) {
          newErrors[field] = "This field is required.";
        } else if (
          validationRules[field]?.label?.required &&
          !photographerData[field]?.label
        ) {
          newErrors[field] = "Please Select field";
        }
        if (!photographerData.password && !params.id) {
          newErrors.password = "This field is required.";
        }
        if (!photographerData.firm_address &&!params.id) {
          newErrors.firm_address = "This field is required.";
          }
          if (!photographerData.firm_address &&!params.id) {
            newErrors.firm_address = "This field is required.";
            }
            if (!photographerData.confirmPassword &&!params.id) {
              newErrors.confirmPassword = "This field is required.";
              }
      }
    }


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const isValid = validate();
    console.log("isVa...", isValid, errors);
    if (isValid) {
      setButtonClick(true);
    } else {
      setButtonClick(false);
    }
  };

  const changePassword = async () => {
    let payload: any = {
    password: photographerData?.password,
    confirm_password: photographerData?.confirm_password
    }
    try {
    let response
    if ( photographerData?.password && photographerData?.confirm_password) {
    response = await service.ManagePhotos.changePassword(user_id,payload)
    if (response.status === 200) {
    addToast('Password updated successfully', {
    appearance: 'success',
    autoDismiss: true,
    autoDismissTimeout: 3000,
    });
    } else
    if (photographerData?.password === undefined) {
    addToast('Password is undefined', {
    appearance: 'error',
    autoDismiss: true,
    autoDismissTimeout: 3000,
    });
    return
    } else if (photographerData?.confirm_password === undefined) {
    addToast('Confirm Password is undefined', {
    appearance: 'error',
    autoDismiss: true,
    autoDismissTimeout: 3000,
    });
    return
    }}else{
    addToast('Fields are empty', {
    appearance: 'error',
    autoDismiss: true,
    autoDismissTimeout: 3000,
    });
    }
    } catch (error:any) {
    addToast(error.response.data.message, {
    appearance: 'error',
    autoDismiss: true,
    autoDismissTimeout: 3000,
    });
    }
    }
  useEffect(() => {
    console.log("photographerData", photoData)
  }, [photographerData, photoData])
  return (
    <>
      <Wrapper>
        <Card>
          <div className="row align-items-center mb-4">
            <div className="col-6 text-start ">
              <h4 className="mb-0 textnew fw-600">
                {editId ? "Update" : "Create"} Photographer
              </h4>
            </div>
            {editId && <div className="col-6 text-end ">

              <Button
                className="color p-2 px-4"
                onClick={() => setShowModal(true)}>Change Password</Button>
              <div className="col-12">
                <Modal
                  className="modal-dd py-5"
                  isOpen={showModal}
                  role="dialog"
                  autoFocus={true}
                  centered
                  data-toggle="modal"
                  toggle={() => {
                    setShowModal(!showModal);
                  }}
                // style={{ width: "50vh" }}
                >

                  <ModalHeader className="justify-content-center m-header">
                    Change Password
                  </ModalHeader>
                  <div className="modal-body py-5 px-5">
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validationModal.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="col-md-12">
                        <div className="col-md-6 mb-3 mx-5">
                          <Label className="form-label mx-5">New Password</Label>
                          <Input
                            name="newPassword"
                            className="form-control mx-5"
                            placeholder="Type your New Password"
                            type="text"

                            onChange={(e: any) => {
                              handleChange("password", e.target.value);
                            }}
                            value={photographerData?.password}
                          />
                          {validationModal.touched.newPassword && validationModal.errors.newPassword ? (
                            <FormFeedback type="invalid"></FormFeedback>
                          ) : null}
                        </div>

                        <div className=" col-md-6 mb-3 mx-5">
                          <Label className="form-label mx-5"> Confirm New Password</Label>
                          <Input
                            name="newPassword"
                            className="form-control mx-5"
                            placeholder="Confirm  New Password"
                            type="text"
                            onChange={(e: any) => {
                              handleChange("confirm_password", e.target.value);
                            }}
                            value={photographerData?.confirm_password}
                          />
                          {validationModal.touched.newPassword && validationModal.errors.newPassword ? (
                            <FormFeedback type="invalid"></FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3 text-center">
                          {errors.password && <p>{errors.password}</p>}
                          {errors.confirm_password && <p>{errors.confirm_password}</p>}
                          <button
                            className="btn btn-modal px-5 py-2"
                            type="submit"
                            disabled={(errors.password || errors.confirm_password) && true}
                            onClick={() => changePassword()}
                          >
                            Confirm Password
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </Modal >
              </div>

            </div>}
          </div>

          <div className="row mt-3">
            <div className="col-md-6">
              <Input
                errors={errors.firm_name}
                label={"Firm Name"}
                type={"text"}
                placeholder={"Enter Firm Name"}
                value={photographerData?.firm_name}
                onChange={(e: any) => {
                  handleChange("firm_name", e.target.value);
                }}
              />
            </div>
            <div className="col-md-6">
              <Input
                errors={errors.name}
                label={"Owner Name"}
                type={"text"}
                placeholder={"Enter Owner Name"}
                value={photographerData?.name}
                onChange={(e: any) => {
                  handleChange("name", e.target.value);
                }}
              />
            </div>

            <div className="col-md-6 mt-3">
              <Input
                errors={errors.mobile}
                label={"Phone Number"}
                type={"text"}
                placeholder={"Enter Phone Number "}
                value={photographerData?.mobile}
                onChange={(e: any) => {
                  handleChange("mobile", e.target.value);
                }}
                maxLength={10} 
              />
            </div>
            <div className="col-md-6 mt-3">
              <Input
                errors={errors.email}
                label={"Email"}
                type={"text"}
                placeholder={"Enter Email"}
                value={photographerData?.email}
                onChange={(e: any) => {
                  handleChange("email", e.target.value);
                }}
              />
            </div>
        
            <div className="col-md-6 mt-3">
              <Input
                errors={errors.firm_address}
                label={"Address"}
                type={"textarea"}
                placeholder={"Enter Address"}
                value={photographerData?.firm_address}
                onChange={(e: any) => {
                  handleChange("firm_address", e.target.value);
                }}
              />
            </div>
            {!params.id ?
              <div className="col-md-6 mt-3">
                <Input
                 
                  errors={errors.password}
                  label={"Password"}
                  type={"text"}
                  placeholder={"Enter Password "}
                  value={photographerData?.password}
                  onChange={(e: any) => {
                    handleChange("password", e.target.value);
                  }}
                
                />
              </div> : ''}
            {!params.id ?
              <div className="col-md-6 mt-3">
                <Input
                  errors={errors.confirmPassword}
                  label={"Confirm Password"}
                  type={"text"}
                  placeholder={"Confirm Password "}
                  value={photographerData?.confirmPassword}
                  onChange={(e: any) => {
                    handleChange("confirmPassword", e.target.value);
                  }}
                />
              </div> : ''}

          </div>


          <div className="row mt-4">

          </div>
          {/* </div> */}
          <div className="justify-content-center d-flex mt-4">
            <Button
              name={`${params.id ? "Update" : "Create"} Photographer`}
              className={"w-25 m-4 p-2"}
              onClick={() => {
                handleSubmit();
              }}
            />
          </div>

        </Card>
      </Wrapper>
    </>
  );
};

export default Index;
