import auth from './auth'
import dashboard from './dashboard'
import ManagePhotos from './managephotos'

const service = {
  auth,
 dashboard,
 ManagePhotos
}
export default service
