import React, { useState } from 'react'
// import image from '../../../assets/images/login-card.png'
import { Input, Label } from "reactstrap";
import './login.scss'
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import services from '../../../api/services'
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FaRegEyeSlash } from "react-icons/fa";

const Index = () => {
  const navigate = useNavigate()
  const { addToast } = useToasts();
  const [errors, setErrors] = useState<any>({});
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isUsernameValid, setUsernameValid] = useState(true);
  const [isPasswordValid, setPasswordValid] = useState(true);
  const [passwordShow, setPasswordShow] = useState(false);
  const handleUsernameChange = (e: any) => {
    setUsername(e.target.value);
    setUsernameValid(true);
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    setPasswordValid(true);
  };
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // Perform validation
    let isValid = true;

    if (username.trim() === '') {
      setUsernameValid(false);
      isValid = false;
    }

    if (password.trim() === '') {
      setPasswordValid(false);
      isValid = false;
    }
    if (isValid) {
      let payload = {
        "grant_type": "password",
        "username": username,
        "password": password,
        "scope": 1
      }
      //TODO : need to change the basic auth token 
      const headers = {
        'Authorization': 'Basic YXBwbGljYXRpb246c2VjcmV0',
        "Content-Type": "application/x-www-form-urlencoded"
      };
      try {
        let response = await services.auth.login(payload, headers,)
        if (response?.status === 200) {
          localStorage.setItem("loginResponse", JSON.stringify(response.data.data))
          addToast(response?.data?.message, {
            appearance: 'success',
            autoDismiss: true,
            autoDismissTimeout: 3000,
          });
          localStorage.setItem("access_token", response?.data?.data?.accessToken)
          navigate("/mydashboard")
        }
      } catch (error: any) {
        console.log(error)
        addToast("Invalid Credentials", {
          appearance: 'error',
          autoDismiss: true,
          autoDismissTimeout: 3000,
        });
      }

    }
  };
  return (
    <div className='container'>
      <div className='row align-items-center'>
        {/* <div className='col-md-12'> */}
        <div className='login-card-parent d-flex align-items-center justify-content-center'>

          <div className='login-card' >
            <div className='row align-items-center'>
              <div className='col-md-6 p-5'>
                <form onSubmit={handleSubmit}>
                  <div className="">
                    <h2 className='para-login'>Admin Login</h2>
                  </div>


                  <div className="mt-4">
                    <div className=""><Label className=" d-flex form-label email">Email Address</Label>
                      <Input type={"email"}
                        errors={errors.email}
                        // className="form-control e-form"{!isUsernameValid ? 'error' : ''}
                        //  className={!isUsernameValid ? 'error' : ''}
                        className="form-control e-form" placeholder="Enter your email address"
                        onChange={handleUsernameChange} label={"Email address"}
                        defaultValue={username}
                      />
                      {!isUsernameValid && <p className="error-text">Email is required</p>}
                    </div>
                  </div>


                  <div className="mt-4">
                    <div className=""> <Label className="d-flex pasword">Password</Label>
                    <div className='d-flex justify-content-end'> <button onClick={() => setPasswordShow(!passwordShow)}
                          className="btn  bg-transparent eyebtn mt-2 mx-2"
                          style={{ border: "none", borderLeftStyle: "hidden" }} type="button" id="password-addon">
                        {passwordShow ? <MdOutlineRemoveRedEye /> : <FaRegEyeSlash />}
                          </button> 
                         
                          </div>
                         
                      <Input  onChange={handlePasswordChange}
                       type={passwordShow ? "text" : "password"}
                        className='e-pas'
                        name="password"
                        placeholder="Enter Password"
                        defaultValue={password} 
                        />
                     {!isPasswordValid && <p className="error-text">Password is required</p>}          
                    </div>
               
                  </div>

                  <div className='mt-5 py-3'>
                    <div className="buttonss ">
                      <button className="btn w-100 nlog-b " type={"submit"}>Login</button>
                    </div>
                  </div>
                </form>
              </div>

              <div className="col-md-6">
                <div className='login-image'></div>

                {/* <img src={image} className='image-login' alt="image"/>  */}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>



  )
}

export default Index
 