import React from 'react';
import { Routes, Route } from 'react-router-dom';
import './App.css';
import './assets/scss/common.scss'
import { privateRoutes, publicRoutes } from "./routes"
import AuthLayout from './layout/AuthLayout'
import NonAuthLayout from './layout/NonAuthLayout'
import Interceptor from './api/interceptor'

function App() {
  return (
    <div className="App">
      <Interceptor />
      <Routes>
        {privateRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <AuthLayout>
                {route.component}
              </AuthLayout>
            }
            key={idx}
          />
        ))}
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
          />
        ))}
      </Routes>
    </div>
  );
}

export default App;
