import React from 'react'

const Index = () => {
  return (
    <div>Index Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima illum, placeat dolor totam cupiditate atque fugit iste accusantium ducimus omnis ipsa nam quae vel natus nobis aliquam repudiandae! Explicabo.
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Repellendus earum aspernatur vel quisquam. Quasi ea itaque error ab ut cum perspiciatis dolorem! Exercitationem, facilis aperiam non soluta hic perferendis modi?
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eveniet sed dolor, vol</div>
  )
}

export default Index