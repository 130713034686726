import React from 'react'

const Index = () => {
  return (
    <div>Index ghjfffffffffffffghjghjghjghjghjgh
        fghhhhhhhhhhhhhhhhhhhhh
        ghjfgh
        ghjjjjjjjj
        fhgjjdd
        hjjjjjjjjjjj
        gfh
    </div>
  )
}

export default Index