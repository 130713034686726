
// import App from "./App"

import AddPhotographer from "./AddPhotographer"
import ManagePhotos from "./ManagePhotos"
import MyDashboard from "./MyDashboard"

const organisms = {
    
    MyDashboard,
    ManagePhotos,
    AddPhotographer
    
}
export default organisms