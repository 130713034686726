import React from 'react'
import molecules from '../../../molecules'
// import Dashboard from '../../../molecules/dashboard'
const {Dashboard} = molecules
export default function index() {
  return (
  <>
  {/* <Dashboard/> */}
  </>
  
  )
}
