// eslint-disable-next-line import/no-anonymous-default-export
export default {
    ROLE: {
      ADMIN: '1',
      MANAGE: '2',
    },
    SCOPE: {
      ADMIN: '1',
      PHOTOGRAPHER: '2',
      CLIENT: '3',
    },
  
    STATUS: {
      ACTIVE: '1',
      INACTIVE: '2',
    },
  
  }
  