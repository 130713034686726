import React from 'react'
import AuthLayout from '../../../layout/AuthLayout'
export default function index() {
  return (
    <AuthLayout >
      <div style={{background:"#000"}}>
      invzxcvzxcvzdex
        </div></AuthLayout>
  )
}
