import Button from './buttons';
import Search from '../Input/Search';
import Table from '../Input/Table';
import Input from '../Input'

const atoms = {
    Button,
    Search,
    Table,
    Input,
}
export default atoms