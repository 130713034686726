import React from 'react'
import './wrapper.scss'
const Wrapper = ({ children }: any) => {
    return (
        <div className="wrapper-layout">
            <main className="wrapper-main">
                <div className="wrapper-card" >
                    {children}
                </div>
            </main>
        </div>
    )
}



export default Wrapper