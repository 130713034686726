import React, { useEffect, useState } from 'react'

// import atoms from '../../components/atoms'
import service from '../../api/services'
import organisms from '../../organism'
import { useToasts } from 'react-toast-notifications';
import DataTable from "../../components/Input/Table";
import { AxiosResponse } from 'axios';
// const { Table, Button,  } = atoms
const { ManagePhotos } = organisms

const Index = () => {
  const { addToast } = useToasts();
  const [photosList, setPhotosList] = useState()
  const [currentPage, setCurrentPage] = useState(1);
  const [limitPerPage, setLimitPerPage] = useState(10);
  const [search, setSearch] = useState('');
  

  

  const getPhotosList = async () => {
    console.log('currentPage',currentPage)
    try {
      let response = await service.ManagePhotos.view({ "page": currentPage, "search": search, "limit": limitPerPage })
      if (response?.status === 200) {
        setPhotosList(response.data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  
  useEffect(() => {
    getPhotosList()
  }, [currentPage,search,limitPerPage])


  

  // const handleStatus = async (id: string) => {
  //   try {
  //     let response = await service.contractor.status(id);
  //     console.log('hiii status response',response)
  //     if (response.status === 200) {
  //       // addToast('Status updated successfully', {
  //       //   appearance: 'success',
  //       //   autoDismiss: true,
  //       //   autoDismissTimeout: 3000,
  //       // });
  //       getContactorList()
  //     }
  //   } catch (error) {

  //   }
  // }
  return (
    <>
      <ManagePhotos 
       setLimitPerPage={setLimitPerPage}
       setCurrentPage={setCurrentPage}
       photosList={photosList}
       setSearch={setSearch}
      />
    </>
  )
}

export default Index