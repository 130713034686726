import React from 'react';
import './button.scss'
import { ButtonProps } from './interface';

const Button = ({ type, name, className, isDisabled, children, onClick, id, color, style }: ButtonProps) => {
  return <button id={id}
    style={style}
    onClick={onClick}
    color={color}
    disabled={isDisabled}
    className={[className, isDisabled && 'disable-btn', 'primary-btn', 'add-btn','mb-0'].join(' ')}
    type={type} >{name || children} </button>;
};
export default Button