import React, { useState, useEffect } from 'react'
import organisms from '../../organism'
import service from '../../api/services'
// import { getDropdown, getUniqueObj } from '../../../constants/utils'
import { useToasts } from 'react-toast-notifications'
import { useNavigate, useParams } from 'react-router-dom'
import constant from '../../constants/constant'
const { AddPhotographer } = organisms
const Index = () => {
    const { addToast } = useToasts();
    const params: any = useParams()
    const photoId = params.id
    const navigate = useNavigate()

    const [buttonClick, setButtonClick] = useState(false)
    const [user_id,setUserId] = useState('')
    const [photographerData, setPhotographerData] = useState<any>({
        name: '',
        email: '',
        mobile: '',
        password: '',
        firm_name: '',
        firm_address: '',
        confirmPassword:'',
    })



    const submitPhotographer = async () => {
        console.log("s")
        let payloadOne = {
            name: photographerData.name,
            //   status: photographerData.status,
            email: photographerData.email,
            mobile: photographerData.mobile,
            firm_name: photographerData.firm_name,
            firm_address: photographerData.firm_address,
            password: photographerData.password,
            confirmPassword:photographerData.confirmPassword,
        }

        let payload = photographerData?
            { ...payloadOne } : payloadOne
        try {
            let response
            if (params.id) {
                response = await service.ManagePhotos.edit(user_id, payload)
            } else {
                response = await service.ManagePhotos.add(payload)
            }
            if (response.status === 201 || response.status === 200) {
                addToast("Submit Successfully", {
                    appearance: 'success',
                    autoDismiss: true,
                    autoDismissTimeout: 3000,
                });
                navigate(-1)
            }
        } catch (error: any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    useEffect(() => {

        if (buttonClick) {
            submitPhotographer()
            setButtonClick(false)
        }
    }, [buttonClick])

    useEffect(() => {
        console.log("photoId",photoId,params)
        if (params.id && !buttonClick) {
            getDatabyId()
        }
    }, [])

    const getDatabyId = async () => {
        try {
            let response = await service.ManagePhotos.getDetailById(params?.id)
            if (response.status === 200) {
                handleEditResponse(response.data.data)
                setUserId(response.data.data[0].user_id._id)

            }
        } catch (error:any) {
            addToast(error.response.data.message, {
                appearance: 'error',
                autoDismiss: true,
                autoDismissTimeout: 3000,
            });
        }
    }


    const handleEditResponse = (response: any) => {
        console.log("response" ,response)
        setPhotographerData({
            name: response[0].user_id.name,
            email: response[0].user_id.email,
            mobile: response[0].user_id.mobile,
            password: response[0].user_id.password,
            firm_name: response[0].firm_name,
            firm_address: response[0].firm_address,
            confirmPassword:''
        })
    }
      
   
 
    return (
        <>
        <>{console.log("photographerData",photographerData)}</>
            <AddPhotographer
                photographerData={photographerData}
                setPhotographerData={setPhotographerData}
                setButtonClick={setButtonClick}
                user_id={user_id}
            />
        </>
    )
}

export default Index



